<template>
  <div v-if="permission.access" class="d-inline">
    <div class="row d-flex justify-content-between">
      <div class="col-md-6">
        <Input
            v-model="search.search_data"
            style="max-width: 350px"
            class="d-inline-block mb-2"
            placeholder="Search roles"
            type="text"
        />
      </div>
      <div class="col-md-6 text-right">
        <Tooltip content="add" placement="top-end">
          <b-button
              @click="openModal()"
              variant="primary"
              size="md"
              style="margin-bottom: 10px"
          >
            <feather-icon icon="PlusCircleIcon"/>
          </b-button>
        </Tooltip>
      </div>
    </div>

    <div class="card card-body table-responsive">

      <table class="table">
        <thead>
        <tr>
          <th class="text-center">Sl</th>
          <th class="text-center">Name</th>
          <th class="text-center">Permissions Count</th>
          <th class="text-center">Created By</th>
          <th class="text-center">status</th>
          <th class="text-center">created at</th>
          <th class="text-center">updated at</th>
          <th class="text-center" v-if="permission.edit || permission.delete">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="roles.data.length" v-for="(role, index) in roles.data" :key="index" class="text-center">
          <td>
            {{ roles.from + index }}
          </td>
          <td>
            {{ role.name }}
          </td>
          <td>
            {{ role.permissions_count }}
          </td>
          <td>
            {{ role.created_by.name }}
          </td>
          <td>
             <span class="badge " :class="role.status == true ? 'badge-success' : 'badge-danger'">
              {{ role.status == true ? 'Active' : 'Inactive' }}
             </span>
          </td>
          <td>
            {{ role.created_at | date_format }}
          </td>
          <td>
            {{ role.updated_at | date_format }}
          </td>
          <td v-if="permission.edit || permission.delete">
            <Tooltip content="edit" placement="top-end" v-if="permission.edit">
              <b-button
                  @click="editRole(role.id)"
                  variant="primary"
                  size="sm"
                  style="margin-right: 5px"
              >
                <feather-icon icon="EditIcon"/>
              </b-button>
            </Tooltip>
            <Tooltip content="delete" placement="top-end" v-if="role.deletable && permission.delete">
              <b-button
                  @click="deleteRole(role.id)"
                  variant="danger"
                  size="sm"
                  style="margin-right: 5px"
              >
                <feather-icon icon="TrashIcon"/>
              </b-button>
            </Tooltip>
          </td>
        </tr>

        <tr v-else>
          <td class="text-center" colspan="6">
            <h4>No data found!</h4>
          </td>
        </tr>
        </tbody>
      </table>


      <Modal v-model="modalShow" title="Manage Role:" width="65%" :before-close="clear">
        <div>
          <form @submit.prevent="save()" @keydown="form.onKeydown($event)">
            <div>
              <div class="d-inline">
                <div class="card card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="form-group col-md-4">
                      <label>Role*</label>
                      <Input
                          v-model="form.name"
                          placeholder="Enter role"
                          style="width: 100%"
                          type="text"
                      />
                      <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="negotiation"> Status* &nbsp;</label>
                      <b-form-checkbox
                          name="status"
                          style="margin-top: 10px; padding-top: 4px"
                          v-model="form.status"
                          switch
                          inline
                          id="status"
                      >
                      </b-form-checkbox>
                      <has-error
                          :form="form"
                          field="status"
                      ></has-error>
                    </div>
                    <div class="col-md-4 text-right">
                      <b-button style="margin-right: 5px" variant="primary" type="submit" :disabled="form.busy">
                        {{ form.id ? "Update" : "Save" }}
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="card card-body">
                  <div class="d-flex align-items-center">
                    <div class="form-group w-100">
                      <label class="mb-1">Select Permission*</label>
                      <b-row>
                        <b-col md="4" v-for="(permission, index) in permissions" :key="index">
                          <div class="mt-1">
                            <p class="mb-0"><b>{{ permission.name }}</b></p>
                          </div>
                          <hr class="mt-0" style="margin-top: 5px!important;">
                          <div class="d-inline" v-for="(data, index) in permission.permissions" :key="index">
                            <div>
                              <div
                                  class="custom-control custom-checkbox mb-2"
                              >
                                <input
                                    :id="'checkbox' + data.id" v-model="form.permissions" :value="data.id"
                                    type="checkbox"
                                    class="custom-control-input"
                                    name="permissions"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="'checkbox' + data.id">
                                  {{ data.name }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </b-col>

                      </b-row>
                      <has-error :form="form" field="permissions"></has-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>

    <b-card-body>
      <b-row>
        <b-col cols = "6">
            <span class = "text-muted"
            >Showing {{ roles.from }} to {{ roles.to }} of
              {{ roles.total }} entries</span
            >
        </b-col>
        <b-col cols = "6">
          <pagination
              class = "mb-0"
              :data = "roles"
              @pagination-change-page = "getRoles"
              align = "right"
              :show-disabled = "true"
              :limit = "5"
          ></pagination>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton, BFormTextarea, BCardBody,
} from "bootstrap-vue";
import {HasError, AlertError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
        permissions: [],
      }),
      search: {
        search_data: "",
        _method:"GET",
        page: 1,
      },
      roles: {},
      modalShow: false,
      permissions: []
    };
  },
  components: {
    BCardBody,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    this.getRoles();
    this.getPermissions();
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.role;
    },
  },
  methods: {
    getPermissions() {
      axios
          .get("/app/all-permissions")
          .then((res) => {
            this.permissions = res.data.permissions;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },

    getRoles(page) {
      this.search.page = page || this.search.page;
      axios
          .post(`/app/role?page=`+this.search.page, this.search)
          .then((res) => {
            this.roles = res.data.roles;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },

    editRole(id) {
      axios
          .get("/app/role/" + id)
          .then((res) => {
            this.form.permissions = res.data.permission_id;
            this.form.id = res.data.role.id;
            this.form.name = res.data.role.name;
            this.form.status = res.data.role.status ? true : false;
            this.openModal();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },

    save() {
      if (this.form.id) {
        this.form
            .put("/app/role/" + this.form.id)
            .then((response) => {
              this.s(response.data.message);
              this.getRoles();
              this.closeModal();
            })
            .catch((e) => {
              this.e(e.data.errors);
            });
      } else {
        this.form
            .post("/app/role")
            .then((response) => {
              this.s(response.data.message);
              this.getRoles();
              this.closeModal();
            })
            .catch((e) => {
              this.e(e.data.message);
            });
      }
    },

    deleteRole(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
              .delete("/app/role/" + id)
              .then((res) => {
                this.getRoles();
                this.s(res.data.message);
              })
              .catch((e) => {
                this.e(e.data.error);
              });
        }
      });
    },

    clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.deletable = null;
      this.form.status = true;
      this.form.permissions = [];
    },

    openModal() {
      this.modalShow = true;
    },

    closeModal() {
      this.clear();
      this.modalShow = false;
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getRoles();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getRoles();
    },
  },

  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>

<style scoped>
.onboard-form .card-body {
  display: block !important;
}

.experience-card {
  background-color: #fafafa;
}

</style>
